
import { is_enabled_module, is_enabled_setting } from '@/Composable/usePermission'
import { usePage } from '@inertiajs/vue3';
import { computed } from 'vue';
const page = usePage();
const settings = computed(() => page.props.setting ?? {})
const appLocale = computed(() => page.props.locale ?? {})

export default {
    install: (app) => {
        const titleHead = (title) => {
            if (import.meta.env.SSR) {
                if (!settings.value.site_title[appLocale.value]) {
                    return `${title}`;
                }
                return `${title}` + ' | ' + settings.value.site_title[appLocale.value];
            }
            return title;
        }

        app.config.globalProperties.is_enabled_module = is_enabled_module;
        app.config.globalProperties.is_enabled_setting = is_enabled_setting;
        app.config.globalProperties.titleHead = titleHead;


        app.mixin({
            methods: {
                is_enabled_module: is_enabled_module,
                is_enabled_setting: is_enabled_setting,
                titleHead: titleHead,

            },
        });

        app.provide('is_enabled_module', is_enabled_module)
        app.provide('is_enabled_setting', is_enabled_setting)
        app.provide('titleHead', titleHead)

    }
}
